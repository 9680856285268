
.btn-circle.btn-xl {
    width: 100%;
    height: 100%;
    padding: 13px 18px;
    border-radius: 50%;
    font-size: 50px;
    text-align: center;
    color: white;

    background-color: #8FD14F;

    box-shadow: 0 0 0 10px #8FD14F75,
                0 0 0 20px #8FD14F50;

    margin-bottom: 50px;
}