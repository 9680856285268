.headerContainer {
  /* position: absolute; */
  max-width: 100%;
  /* top: 0px; */
  /* z-index: -9999 !important; */
}

.header {
  color: white;
  height: 50px;
}

.subheader {
  background: white !important;
  /* color: white; */

  height: 50px;
}

.logo {
  background-image: url(/public/assets/imgs/Alpaca-logo-white-outline.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 150px;
  height: 150px;
  position: relative;
  /* right: 75px; */
  top: -35px;
}

.subheading-text {
  position: relative;
  z-index: 1;
  font-family: "Caveat Brush", cursive;
  line-height: 50px !important;
  font-size: 2vw !important;
}

.circle {
  width: 120px;
  height: 120px;

  background: white;
  border-radius: 120px;
  -webkit-border-radius: 120px;
  -moz-border-radius: 120px;

  position: relative;
  top: -35px;

  text-align: center;
}

.cloud {
  width: 120px;
  height: 120px;

  background: white;
  border-radius: 120px;
  -webkit-border-radius: 120px;
  -moz-border-radius: 120px;

  position: relative;
  top: -35px;
  /*right: 100px;*/
}

.cloud:after,
.cloud:before {
  content: "";
  position: absolute;
  background: white;
  top: 20px;
}

.cloud:before {
  left: 100px;

  width: 80px;
  height: 80px;
  border-radius: 80px;
  -webkit-border-radius: 80px;
  -moz-border-radius: 80px;
}

.cloud:after {
  right: 100px;

  width: 80px;
  height: 80px;
  border-radius: 80px;
  -webkit-border-radius: 80px;
  -moz-border-radius: 80px;
}

.leftPosition {
  position: absolute;
  top: 30px;
  left: -50px;
  width: 60px;
  z-index: 1;
}

.centrePosition {
  position: absolute;
  width: 80px;
  left: 20px;
  top: 20px;
}

.rightPosition {
  position: absolute;
  top: 30px;
  left: 110px;
  width: 60px;
}

@media only screen and (max-width: 1181px) {
  .header {
    height: 5vw;
  }

  .subheader {
    height: 5vw;
  }

  .logo {
    background-size: auto 15vw;
    height: 15vw;
    top: -3.5vw;
  }

  .subheading-text {
    line-height: 5vw !important;
    font-size: 1.9vw !important;
  }

  .cloud {
    width: 12vw;
    height: 12vw;

    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;

    position: relative;
    top: -3.5vw;
  }

  .cloud:after,
  .cloud:before {
    top: 2vw;
  }

  .cloud:before {
    left: 10vw;

    width: 8vw;
    height: 8vw;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
  }

  .cloud:after {
    right: 10vw;

    width: 8vw;
    height: 8vw;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
  }

  .leftPosition {
    top: 3vw;
    left: -5vw;
    width: 6vw;
  }

  .centrePosition {
    width: 8vw;
    left: 2vw;
    top: 2vw;
    z-index: 2;
  }

  .rightPosition {
    top: 3vw;
    left: 11vw;
    width: 6vw;
  }
}

@media only screen and (max-height: 600px) {
  .header {
    height: 40px; /* Set a smaller height for the header */
  }

  .subheader {
    height: 40px; /* Set a smaller height for the subheader */
  }

  .mainHeaderText {
    font-size: 2rem;
  }

  .logo {
    background-size: auto 120px; /* Adjust the size of the logo */
    height: 120px; /* Adjust the height of the logo */
    top: -25px; /* Adjust the top position of the logo */
  }

  .subheading-text {
    line-height: 40px !important; /* Adjust line height for the subheading text */
    font-size: 18px !important; /* Adjust font size for the subheading text */
    text-align: center;
  }

  .cloud {
    width: 100px; /* Adjust the size of the cloud */
    height: 100px; /* Adjust the size of the cloud */
    top: -25px; /* Adjust the top position of the cloud */
  }

  .cloud:after,
  .cloud:before {
    top: 10px; /* Adjust the top position of the cloud pseudo-elements */
  }

  .cloud:before {
    left: 80px; /* Adjust the left position of the cloud pseudo-element */
    width: 60px; /* Adjust the width of the cloud pseudo-element */
    height: 60px; /* Adjust the height of the cloud pseudo-element */
  }

  .cloud:after {
    right: 80px; /* Adjust the right position of the cloud pseudo-element */
    width: 60px; /* Adjust the width of the cloud pseudo-element */
    height: 60px; /* Adjust the height of the cloud pseudo-element */
  }

  .leftPosition {
    top: 15px; /* Adjust the top position of the left position element */
    left: -40px; /* Adjust the left position of the left position element */
    width: 40px; /* Adjust the width of the left position element */
  }

  .centrePosition {
    width: 60px; /* Adjust the width of the centre position element */
    left: 10px; /* Adjust the left position of the centre position element */
    top: 10px; /* Adjust the top position of the centre position element */
    z-index: 2; /* Ensure the centre position element is above other elements */
  }

  .rightPosition {
    top: 15px; /* Adjust the top position of the right position element */
    left: 70px; /* Adjust the left position of the right position element */
    width: 40px; /* Adjust the width of the right position element */
  }
}
