.icon {
  filter: invert(100%);
  fill: white;
}

.orientation-prompt {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;

  h2 {
    color: #fff;
    font-size: 24px;
    text-align: center;
  }

  .turning-animation {
    display: inline-block;
    position: absolute; /* Position the spinning animation */
    top: 450px; /* Adjust the top value as needed */
    // left: 50%; /* Center horizontally */
    transform: translateX(-50%);
    animation: rotateAntiClockwise 3s linear infinite;
  }

  .alpaca-image {
    position: absolute;
    top: 100px; /* Adjust the top value as needed */
    left: -130px; /* Adjust the left value as needed */
    // width: 100px; /* Adjust the width value as needed */
    height: 400px;
  }

  .speech-bubble-container {
    position: absolute;
    top: 100px; /* Adjust the top value as needed */
    right: 40px; /* Adjust the left value to position it right of the alpaca */
    // width: 150px; /* Adjust the width value as needed */
    height: auto;
  }

  .speech-bubble-image {
    max-width: 100%;
    height: auto;
  }

  .speech-bubble-text {
    position: absolute;
    top: 50%; /* Adjust the top value to vertically center the text */
    left: 55%; /* Adjust the left value to horizontally center the text */
    transform: translate(
      -50%,
      -50%
    ); /* Center the text both vertically and horizontally */
    text-align: center;
    color: #56544a;
    font-size: 28px; /* Adjust font size as needed */
    // opacity: 0.7;

    // font-weight: 900;
  }
}

@keyframes rotateAntiClockwise {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(
      -360deg
    ); /* Negative value for counterclockwise rotation */
  }
}
