
/*
 * Importing Bootstrap styling in it's own 'cascade layer' to simplify 
 * how we override styling for Alpaca specific things.
 *
 * This is a relatively new feature of CSS that is only supported by
 * browser versions released in 2022
 * - Edge > 99
 * - Firefox > 97
 * - Chrome > 99
 * - Safari > 15.4
 */
@import 'bootstrap/dist/css/bootstrap.min.css' layer();

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/utilities";

$utilities: map-merge(
  $utilities,
  (
    "height": map-merge(
      map-get($utilities, "height"),
      (
        values: map-merge(
          map-get(map-get($utilities, "height"), "values"),
          (10: 10%),
        ),
      ),
    ),
  )
);


@font-face {
  font-family: "Adia_Demo";
  src: local("Adia_Demo"),
    url("./fonts/Adia_Demo.ttf") format("truetype");
  font-weight: normal;
}

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  font-family: 'Adia_Demo' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
  max-height: 100vh;
  background-color: #5DBDD6 !important;
  overflow: hidden;
  touch-action: pan-x pan-y; // to prevent pinch and zoom in safari
  touch-action: none !important;
  -webkit-touch-callout: none;
  
  -webkit-tap-highlight-color: transparent; // to turn off highlight
  user-select: none;
  -webkit-text-size-adjust: none;

  // touch-event
}

.realBody {
  /*background-color: #ffffff !important;*/
  background-color: #5DBDD6 !important;
}

.practiceBody {
  background-color: #5DBDD6 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sv-footer__next-btn {
  z-index: 99999;
}

@keyframes borderBlink {

  from,
  to {
    border-color: transparent;
    border-radius: 10%;
  }

  50% {
    border-color: rgb(26, 179, 148);
    border-radius: 10%;
    border: solid 3px rgb(26, 179, 148);
  }
}

@keyframes borderBlinkCircle {

  from,
  to {
    border-color: transparent;
    border-radius: 50%;
  }

  50% {
    border-color: rgb(26, 179, 148);
    border-radius: 50%;
    border: solid 3px rgb(26, 179, 148);
  }
}

.highlight {
  animation: borderBlink 1s step-end infinite;
}

.highlight-circle {
  animation: borderBlinkCircle 1s step-end infinite;
}

.container .row {
  margin: 0;
}

.container .row:nth-child(2) {
  height: 50%;
}

/*
.sv-image img {
  height: 50%;
}

.sv-page {
  width: 100%;
  margin: 0;
}

.sv-question__content {
  margin: 0 auto;
}

.sv-question__content .sv-imagepicker div {
  position: relative;
}

fieldset .sv-imagepicker {
  margin: 0;
}
*/

/* .sv-imagepicker { */
  /* margin-right: 5%; */
  /* margin-left: 5%; */
/* } */

/*
.sv-imagepicker__item {
  padding: 0;
}
*/



.container-fluid {
  height: 100%;
}




/* .sv-root-modern .sv-imagepicker__item--allowhover:hover .sv-imagepicker__image {
  border-color: none;
  border: none;
  color: none;
  background-color: none;
}

.sv-imagepicker__item--allowhover:hover .sv-imagepicker__image {
  border-color: none;
  border: none;
  color: none;
  background-color: none;
}

.sv-root-modern .sv-imagepicker__item--allowhover:hover .sv-imagepicker__image {
  background-color: none;
  border-color: none;
  color: none;
  border: none;
} */

/****************** main styling used. controls primarily tasks 2,3,4,6 */



/* holds reference image for tasks 2, 3 & 4 */
.image-fit {
  margin-top: 2.5%;
  max-width: 60%;
  height: 90%;
}


/****************** all styling for task1  */

/* .sv-imagepicker .sv-imagepicker__item */
/* controls spacing and layout for task1  */


.sv-imagepicker > .rhymeSpacing {
  /* border: 1px red solid; */
  /* border-radius: 50%; */
  /* margin-left: 4%; */
  /* margin-right: 3%; */
  /*  margin-top: 7%; */
}


/* .sv-imagepicker .sv-imagepicker__item label */
/* controls shape and size of circles for task1 */
.rhymeCircles {
  /* background-color: white; */
  border: 0.5vh solid #5DBDD6;
  border-radius: 50%;
  width: 60vh;
  height: 60vh;
}

.green-highlight {
  background-color: rgba(112, 220, 112, 0.372);
} 

.red-highlight {
  background-color: rgba(208, 93, 93, 0.375);
} 

/* controls image placement for task1 */
.rhymeCircles img {
  border-radius: 50%;
  width: 80%;
  height: 80%;
  margin-top: 10%;
  z-index: -999;
}

/****************** all styling for task4 rating system  */

/* centers ratings on all screens */
.sv-rating {
  display: flex;
  justify-content: center;
}

/* controls size and layout of stars */
.sv-rating__item {
  padding-top: 2%;
  transition: all 0.2s ease;
  float: right;
  font-size: 300%;
}

/* controls spacing and border  */
/*
.sv-rating fieldset label .sv-rating__item-text {
  color: #fd4;
  border: solid 0.1875em #fd4;
  margin-right: 2.5vh;
  margin-left: 2.5vh;
  border-radius: 50%;
}
*/

/* styles hover over  */
/*
.sv-rating__item-text:hover {
  background-color: #16971b !important;
  color: white !important;
  border-color: #16971b !important;
}
*/

/* input:not(:checked) ~ .sv-rating__item-text:hover, 
input:not(:checked) ~ .sv-rating__item-text:hover ~ label .sv-rating__item-text {
  background-color: rgb(26, 179, 148) !important;
  color: white;
  border-color: rgb(26, 179, 148) !important;
} */

/*
.sv-rating__item--selected ~ .sv-rating__item .sv-rating__item-text {
  background-color: rgb(26, 179, 148) !important;
  color: white;
  border-color: rgb(26, 179, 148);
}
*/


/****************** all styling for task5  */

/* acts similar to .sv-imagepicker__item */
/* for task5 */
.letter-imagepicker__item {
  cursor: pointer;
  border: none;
  padding: 0.24em;
}

/* temp fix to fix box check adopting parent properties */
/* controls image placement for task5 */
.letter-imagepicker .sv-imagepicker__item label div div img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 5%);
  border-radius: 10%;
  width: 100%;
  height: 16vh;
}

/* controls container spacing for letters */
.letter-imagepicker .sv-imagepicker__item {
  position: absolute;
  width: 0;
  margin-right: 12%;
  margin-left: 9%;
}

/* styles letter container boxes */
.letter-imagepicker .sv-imagepicker__item label {
  position: relative;
  text-align: center;
  border-radius: 10%;
  background-color: white;
  top: 50%;
  left: 50%;
  border: 1px solid black;
  width: 23vh !important;
  height: 18vh !important;
}


// .sv-imagepicker__item--allowhover {
//   color: #fd4;
// }

.grey {
  background: #56544a !important;
}

.red {
  background: #d15755;
}

.orange {
  background: #e28052;
}

.yellow {
  background: #edbe42;
}

.green {
  background: #8ec35e;
}

.green1 {
  background: #c8c82d;
}

.green2 {
  background: #56bf9f;
}

.blue {
  background: #0080b3;
}

.purple {
  background: #635193;
}

.mauve {
  background: #ad629d;
}

.pink1 {
  background: #e587a3;
}

.pink2 {
  background: #e26689;
}


