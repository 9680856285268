.main {
    max-height: 100vh;
}

#alpaca_profile {
    /* position: absolute; */
    bottom: 0;
    /* top: 25vh; */
    /* left: 5%; */
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
}

#alpaca_profile .alpaca-img {
    position: relative;
    float: right;
    max-width: 40vw;
    max-height: 80vh;
    bottom: -25px;
    /* vertical-align: top; */
}

#alpaca_profile .bubble_container {
    position: relative;
    text-align: center;
    display: inline-block;
    /* width: 35vw; */
    /*margin-left: 5vw;*/
}

#alpaca_profile .bubble {
    height: 65vh;
    max-width: 50vw;
}

#alpaca_profile .speechBubbleContent-Large {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 20px;
    font-weight: 600;
    width: 100%;
    padding-left: 15%;
    padding-right: 10%;
    /* margin-left: 30%; */
    /* width: 35vw; */
}

#alpaca_profile .speechBubbleContent-Large h2 {
    font-size: 200%;
    font-weight: 700;
}

#alpaca_profile .speechBubbleContent-Large > button {
    height: 20vh;
    width: 20vh;
    border-radius: 50%;
    font-size: 350%;

}


/*
.alpaca-img {
    height: 25%;
}

.speechBubble {
    position: absolute;
    margin-left: 50px;
    height: 55%;
}

.speechBubbleContent-Large {
    position: relative;
    max-width: 485px;
    left: 100px;
    top: 60px;
}

.speechBubbleContent-Small {
    position: relative;
    max-width: 485px;
    left: 100px;
    top: 60px;
}
*/