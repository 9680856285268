/* InternetBackModal.css */
.internet-bubble-img {
  width: 700px;
  align-items: center;
  padding-top: 10%;
}

.noInternet .modal-content {
  background: transparent;
  border: none; /* Optional: Remove the border */
  box-shadow: none; /* Optional: Remove the box shadow */
}

.noInternet .modal-dialog .modal-content {
  background: transparent;
  border: none; /* Optional: Remove the border */
  box-shadow: none; /* Optional: Remove the box shadow */
}

.noInternet .modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0; /* Remove padding for the image */
}

/* InternetBackModal.css */
.img-container {
  position: relative;
}

.overlay-update {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column; /* Changed to column layout */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  width: 130%;
  height: 100%;
  padding: 20px; /* Adjusted padding */
  box-sizing: border-box;
}

.updateImage {
  width: 120px; /* Adjust according to your image size */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 20px; /* Added margin */
}

.text {
  /* margin-left: 10px; */
  white-space: nowrap;
}

.text p {
  font-size: 20px; /* Adjusted font size */
  text-align: center; /* Centered text */
}

.text h2 {
  font-size: 32px; /* Adjusted font size */
  text-align: center; /* Centered text */
  color: #2c7693;
}

.updateButton {
  margin-top: 20px; /* Added margin */
  background-color: #2c7693;
  width: 150px;
  height: 50px;
  font-size: 20px;
  border-radius: 10px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: block;
}
