.bubble-container {
  position: relative;
  
}

.speech-bubble {
 width: 750px;
  
}

.speech-bubble-content {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  font-size: 24px;
  font-weight: 200;
  max-width: 100%;
  color: #707070;
  text-align: center;
}

.bubble-img{
  padding-right: 20px;
}

  
  .top-row {
    display: flex;
    flex-direction: row;
    position: relative;
  }
  
  .left-col {
    display: flex;
    flex-direction: column;
  }
  
  .alpaca-img-container {
    position: absolute;
    top: 50%;
    right: 5%;
    transform: translateY(-50%);
    max-width: 100%;
    z-index: 1;
  }
  
  .alpaca-img {
    /* height: 100%; */
    /* bottom: 50%; */
    /* right: 5%; */
    height: 600px;
    padding-left: 20%;
  }
  
  
  
  .button-container {
    position: relative;
    max-width: 100%;
    height: auto;
    left: 30%;
  }
  
  .button-background {
    position: relative;
    z-index: 1;
    width: 25vw;
    height: auto;
  }
  
  .resume-button,
  .end-assessment-button {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 10vw;
    height: auto;
  }
  
  
  
  
  button,
  input[type="submit"],
  input[type="reset"] {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }
  
  .big-button-container {
    display: flex;
    flex-direction: row;
    padding-right: 50%;
  }
