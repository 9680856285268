body {
  touch-action: manipulation;
  touch-action: none;
}

.speed-monitor {
  font-family: 'Times New Roman', Times, serif;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .big-ball-spinner {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 12px solid #ffff;
    border-top-color: #FF4D00;
    animation: spin 1s infinite linear;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  