.logout-button {
  width: 100%;
}

@media only screen and (max-height: 600px) {
  /* Other styles for smaller screens */

  .logout-button {
    padding-top: 2px;
    padding-left: 45%;
    width: 160%;
  }
}
