.my-modal {
    width: 100vw !important;
    max-width: 100vw !important;
    z-index: 9999;
}

.modal-button {
    all:initial !important;
}

